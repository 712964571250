<template>
  <div id="AddOrEditGroupModal" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Adicionar Grupo</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            title="Fechar"
            @click="closeModal(false)"
          />
        </div>
        <form @submit.prevent="saveGroup">
          <div class="modal-body">
            <div class="row">
              <div class="col-sm-12 col-md-4">
                <label class="form-label">
                  Início: <span class="text-danger">*</span>
                </label>
                <input
                  type="datetime-local"
                  class="form-control"
                  placeholder="Quando inicia a avaliação?"
                  :class="{
                    'is-invalid': v$.group.startAt.$error,
                  }"
                  v-model="v$.group.startAt.$model"
                />
                <span
                  v-if="v$.group.startAt.required.$invalid"
                  class="invalid-feedback"
                >
                  Campo Obrigatório
                </span>
              </div>
              <div class="col-sm-12 col-md-4">
                <label class="form-label">
                  Término: <span class="text-danger">*</span>
                </label>
                <input
                  type="datetime-local"
                  class="form-control"
                  placeholder="Quando finaliza a avaliação?"
                  :class="{
                    'is-invalid': v$.group.endAt.$error,
                  }"
                  v-model="v$.group.endAt.$model"
                />
                <span
                  v-if="v$.group.endAt.required.$invalid"
                  class="invalid-feedback"
                >
                  Campo Obrigatório
                </span>
              </div>
              <div class="col-12 col-md-4">
                <label class="form-label">
                  Gerar Escolha da Galera: <span class="text-danger">*</span>
                </label>
                <select
                  class="select-control form-control"
                  v-model="group.isChoiceOfGuys"
                >
                  <option value="0">Não</option>
                  <option value="1">Sim</option>
                </select>
              </div>
              <div v-if="group.isChoiceOfGuys == 1" class="col-sm-12 mt-3">
                <label class="form-label">
                  Pergunta: <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Pergunta da escolha da galera"
                  v-model="group.question"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer border-top-0">
            <button
              type="button"
              class="btn btn-link"
              data-bs-dismiss="modal"
              title="Cancelar"
              @click="closeModal(false)"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              title="Atualizar"
              :disabled="v$.$invalid || disableButton"
            >
              <span v-if="disableButton">
                <i class="ph-spinner spinner" />
              </span>
              <span v-else>Cadastrar</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, decimal } from '@vuelidate/validators'

export default {
  name: 'AddOrEditGroupModal',
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  setup() {
    return { v$: useVuelidate() }
  },

  data() {
    return {
      modalInstance: null,
      group: {
        uuid: '',
        startAt: '',
        endAt: '',
        isChoiceOfGuys: 0,
        question: '',
      },
      disableButton: false,
    }
  },

  methods: {
    async saveGroup() {
      try {
        this.disableButton = true
        let response = null
        if (this.group.isChoiceOfGuys === 0) {
          response = await this.$store.dispatch('saveGroup', this.group)
        } else {
          response = await this.$store.dispatch('saveChoiceOfGuys', this.group)
        }
        this.$root.$refs.notification.success(response.message)
        this.closeModal(true)
        this.disableButton = false
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    closeModal(reload) {
      this.modalInstance.hide()
      this.$emit('closeModal', reload)
    },
    showModal() {
      this.modalInstance = new window.bootstrap.Modal(
        document.getElementById('AddOrEditGroupModal'),
        {
          backdrop: 'static',
          keyboard: false,
        },
      )
      this.modalInstance.show()
    },
    async getGroup(uuid) {
      try {
        this.isLoadingSimulation = true
        this.simulation = await this.$store.dispatch('getSimulation', {
          id: this.id,
        })
        this.isLoadingSimulation = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },

  validations() {
    return {
      group: {
        startAt: {
          required,
        },
        endAt: {
          required,
        },
      },
    }
  },

  mounted() {
    if (this.id) {
      this.getGroup(this.id)
    }
    this.showModal()
  },
}
</script>
