<template>
  <div class="row container">
    <div class="col-12 d-flex justify-content-between">
      <h3 class="fw-light">Relatórios</h3>
      <div></div>
    </div>
    <div class="col-12" v-for="(link, index) in links" :key="index">
      <router-link :to="link.path">
        <h4>{{ link.name }}</h4>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ReportsHome',
  data() {
    return {
      links: [
        {
          name: 'Ranking Geral',
          path: '/reports/general',
        },
        {
          name: 'Ranking Mensal',
          path: '/reports/monthly',
        },
        {
          name: 'Escolha da Galera',
          path: '/reports/monthly-choice-of-the-guys',
        },
      ],
    }
  },
}
</script>
