<template>
  <AddOrEditQuestionModal
    v-if="showModalQuestion"
    :id="
      selectedQuestion && selectedQuestion.questionId
        ? selectedQuestion.questionId
        : null
    "
    @closeModal="closeModal"
  />
  <h1 class="fw-light">Detalhes do Questionário</h1>
  <div class="card" v-if="group">
    <div class="card-header d-flex justify-content-between align-items-center">
      <h4 class="fw-normal mb-0">Dados Gerais</h4>
      <button
        class="btn btn-primary rounded-pill"
        @click="updateGroup()"
        :disabled="disableButton"
      >
        Atualizar Informações
      </button>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-6">
          <p><strong>Nº:</strong> {{ group.number }}</p>
        </div>
        <div class="col-12 col-md-6">
          <p><strong>Identificador:</strong> {{ group.uuid }}</p>
        </div>
        <div class="col-sm-12 col-md-6 mb-3">
          <label class="form-label">
            Início: <span class="text-danger">*</span>
          </label>
          <input
            type="datetime-local"
            class="form-control"
            placeholder="Quando inicia a avaliação?"
            v-model="group.startAt"
          />
        </div>
        <div class="col-sm-12 col-md-6 mb-3">
          <label class="form-label">
            Término: <span class="text-danger">*</span>
          </label>
          <input
            type="datetime-local"
            class="form-control"
            placeholder="Quando finaliza a avaliação?"
            v-model="group.endAt"
          />
        </div>
        <div class="col-12 col-md-6">
          <p>
            <strong>Cadastro:</strong>
            {{ dateTimeSqlToDateTimeBR(group.createdAt) }} por
            {{ group.createdBy }}
          </p>
        </div>
        <div class="col-12 col-md-6" v-if="group.updatedBy">
          <p>
            <strong>Atualizado:</strong>
            {{ dateTimeSqlToDateTimeBR(group.updatedAt) }} por
            {{ group.updatedBy }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header d-flex justify-content-between align-items-center">
      <h4 class="fw-normal mb-0">Unidades Participantes</h4>
      <button
        class="btn btn-primary rounded-pill"
        @click="updateSelectedUnities()"
        :disabled="disableButton"
      >
        Atualizar Unidades
      </button>
    </div>
    <div class="card-body">
      <div class="row">
        <div
          class="form-check form-switch mb-2 col-6 col-md-4 col-lg-3"
          v-for="unity in unities"
          :key="`${unity.codColigada}_${unity.codFilial}`"
        >
          <input
            type="checkbox"
            class="form-check-input"
            :id="`unity_cb_${unity.codColigada}_${unity.codFilial}`"
            :value="unity"
            v-model="selectedUnities"
            :checked="unity.id > 0"
          />
          <label
            class="form-check-label"
            :for="`#unity_cb_${unity.codColigada}_${unity.codFilial}`"
            >{{ unity.name }}</label
          >
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header d-flex justify-content-between align-items-center">
      <h4 class="fw-normal mb-0">Questões</h4>
      <button class="btn btn-primary rounded-pill" @click="openModalQuestion()">
        Cadastrar Questão
      </button>
    </div>
    <div
      class="card-body fw-normal"
      v-for="(question, idxQst) in questions"
      :key="question.questionId"
    >
      <div class="title">
        <h6>{{ idxQst + 1 }}) {{ question.question }}</h6>
        <div></div>
        <div
          class="card mb-1"
          :class="option.isCorrect ? 'bg-primary bg-opacity-20' : ''"
          v-for="(option, index) in question.options"
          :key="option.optionId"
        >
          <div class="d-flex align-items-center p-1">
            <span
              class="d-inline-flex align-items-center justify-content-center bg-primary text-white lh-1 rounded-pill w-40px h-40px me-3"
            >
              <span class="letter-icon">{{
                26 > question.options.length ? alphabet[index] : index + 1
              }}</span>
            </span>
            <div>
              <span>{{ option.answer }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-9">
            <p>
              <strong>Mensagem de Acerto:</strong>
              {{ question.messageCorrectOption }}
            </p>
          </div>
          <div class="col-12 col-md-3">
            <p>
              <strong>Pontos:</strong>
              {{ question.points }}
            </p>
          </div>
          <div class="col-12 col-md-9">
            <p>
              <strong>Mensagem de Erro:</strong>
              {{ question.messageErrorOption }}
            </p>
          </div>
          <div class="col-12 col-md-3">
            <p>
              <strong>É avaliado:</strong>
              {{ question.hasCorrectOption ? 'Sim' : 'Não' }}
            </p>
          </div>
          <div class="col-12 text-end">
            <button
              class="btn btn-warning btn-sm rounded-pill me-2"
              :disabled="disableButton"
              @click="editQuestion(question)"
            >
              Editar
            </button>
            <button
              class="btn btn-danger btn-sm rounded-pill"
              :disabled="disableButton"
              @click="deleteQuestion(question)"
            >
              Deletar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { dateTimeSqlToDateTimeBR } from '@/utils/date'
import AddOrEditQuestionModal from '@/components/modals/AddOrEditQuestionModal.vue'

export default {
  name: 'Groups',
  data() {
    return {
      selectedUnities: [],
      alphabet: [],
      selectedQuestion: {
        id: null,
      },
      showModalQuestion: false,
      group: null,
      questions: [],
      unities: [],
      dateTimeSqlToDateTimeBR,
    }
  },
  components: {
    AddOrEditQuestionModal,
  },
  methods: {
    generateAlphabet() {
      // eslint-disable-next-line no-plusplus
      for (let i = 65; i <= 90; i++) {
        this.alphabet.push(String.fromCharCode(i))
      }
    },
    closeModal(needReload) {
      if (needReload) {
        this.getQuestions()
      }
      this.showModalQuestion = false
    },
    async deleteQuestion(question) {
      try {
        this.disableButton = true
        const response = await this.$store.dispatch('deleteQuestion', {
          uuid: this.$route.params.uuid,
          questionId: question.questionId,
        })
        this.$root.$refs.notification.success(response.message)
        this.disableButton = false
        this.getQuestions()
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    openModalQuestion() {
      this.selectedQuestion = null
      this.showModalQuestion = true
    },
    async getGroup() {
      try {
        this.group = await this.$store.dispatch('getGroup', {
          uuid: this.$route.params.uuid,
        })
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    async updateGroup() {
      try {
        this.disableButton = true
        const response = await this.$store.dispatch('updateGroup', this.group)
        this.$root.$refs.notification.success(response.message)
        this.disableButton = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    async getQuestions() {
      try {
        this.questions = await this.$store.dispatch('getQuestions', {
          uuid: this.$route.params.uuid,
        })
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    editQuestion(question) {
      this.selectedQuestion = question
      this.showModalQuestion = true
    },
    async getUnities() {
      try {
        this.unities = await this.$store.dispatch(
          'getUnities',
          this.$route.params.uuid,
        )
        this.selectedUnities = this.unities.filter((unity) => unity.id > 0)
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    async updateSelectedUnities() {
      try {
        this.disableButton = true
        const response = await this.$store.dispatch('updateUnities', {
          uuid: this.$route.params.uuid,
          unities: this.selectedUnities,
        })
        this.$root.$refs.notification.success(response.message)
        this.disableButton = false
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },
  mounted() {
    this.getGroup()
    this.generateAlphabet()
    this.getQuestions()
    this.getUnities()
  },
}
</script>
