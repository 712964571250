<template>
  <div class="row p-3">
    <div class="col-12 d-flex justify-content-between">
      <h3 class="fw-light">
        <router-link to="/reports"
          ><i class="ph-caret-left me-2"></i></router-link
        >Escolha da Galera
      </h3>
    </div>
    <div class="col-12">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="mb-3">
            <label class="form-label">Unidade:</label>
            <select
              class="form-control form-control-select2"
              v-model="selectedUnity"
            >
              <option selected disabled>Selecione uma opção</option>
              <option
                :value="unity"
                v-for="unity in unities"
                :key="`${unity.codColigada}_${unity.codFilial}`"
              >
                {{ unity.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="mb-3">
            <label class="form-label">Mês:</label>
            <select
              class="form-control form-control-select2"
              v-model="selectedMonth"
            >
              <option
                :value="month.number"
                v-for="month in months"
                :key="month.number"
              >
                {{ month.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-12 col-md-2">
          <div class="mb-3">
            <label class="form-label">Ano:</label>
            <select
              class="form-control form-control-select2"
              v-model="selectedYear"
            >
              <option :value="year" v-for="year in years" :key="year">
                {{ year }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-12 col-md-1">
          <div class="mb-3">
            <button
              class="btn btn-icon btn-primary w-100 mt-4"
              @click="getChoiceOfTheGuysMontlyReport"
            >
              <i class="ph-magnifying-glass"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" v-if="!isLoadingData">
      <table class="table table-sm table-striped">
        <thead>
          <tr>
            <th scope="col">Posição</th>
            <th scope="col">Nome</th>
            <th scope="col">Pontos</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in ranking" :key="index">
            <th scope="row">{{ index + 1 }}</th>
            <td>{{ item.answer }}</td>
            <td>{{ item.points }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { dateTimeSqlToDateWithT } from '@/utils/date'

export default {
  name: 'ReportsGeneral',
  data() {
    return {
      unities: [],
      ranking: [],
      selectedUnity: null,
      selectedMonth: null,
      selectedYear: null,
      isLoadingData: true,
      dateTimeSqlToDateWithT,
      months: [
        { name: 'Janeiro', number: 1 },
        { name: 'Fevereiro', number: 2 },
        { name: 'Março', number: 3 },
        { name: 'Abril', number: 4 },
        { name: 'Maio', number: 5 },
        { name: 'Junho', number: 6 },
        { name: 'Julho', number: 7 },
        { name: 'Agosto', number: 8 },
        { name: 'Setembro', number: 9 },
        { name: 'Outubro', number: 10 },
        { name: 'Novembro', number: 11 },
        { name: 'Dezembro', number: 12 },
      ],
      years: [],
    }
  },
  methods: {
    generateYearsArray() {
      const currentYear = new Date().getFullYear()
      this.years = []

      // eslint-disable-next-line no-plusplus
      for (let year = 2022; year <= currentYear; year++) {
        this.years.push(year)
      }
    },
    async getUnities() {
      try {
        this.unities = await this.$store.dispatch('getUnities')
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    async getChoiceOfTheGuysMontlyReport() {
      try {
        if (!this.selectedUnity) {
          this.$root.$refs.notification.warning('Selecione uma unidade.')
          return
        }
        if (!this.selectedMonth) {
          this.$root.$refs.notification.warning('Selecione um mês.')
          return
        }
        if (!this.selectedYear) {
          this.$root.$refs.notification.warning('Selecione um ano.')
          return
        }
        this.isLoadingData = true
        this.ranking = await this.$store.dispatch(
          'getChoiceOfTheGuysMontlyRanking',
          {
            codColigada: this.selectedUnity.codColigada,
            codFilial: this.selectedUnity.codFilial,
            month: this.selectedMonth,
            year: this.selectedYear,
          },
        )
        this.isLoadingData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },
  mounted() {
    this.generateYearsArray()
    this.getUnities()
  },
}
</script>
