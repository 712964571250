<template>
  <router-link :to="`/groups/${model.uuid}`" class="btn btn-success btn-sm py-0"
    >Acessar</router-link
  >
</template>
<style>
revo-grid {
  height: 450px;
}
</style>
<script>
export default {
  name: 'AcessGroupBtn',
  props: {
    model: {
      type: Object,
      required: true,
    },
    prop: {
      type: String,
      required: true,
    },
  },
  data() {
    return {}
  },
  mounted() {
    console.log(this.model)
  },
}
</script>
