const documentStatus = (isObsoleted, expireAt) => {
  if (isObsoleted === '1') {
    return '<span  class="badge bg-secondary">Obsoleto</span>'
  }
}

const entryTypeLabel = (label) => {
  if (label === 'REALIZADO') {
    return '<span class="badge bg-success"><i class="ph-check-circle"></i> Realizado</span>'
  }
}

// eslint-disable-next-line import/prefer-default-export
export { documentStatus, entryTypeLabel }
